import React from 'react';
import kebabCase from "lodash/kebabCase";
import styled from "styled-components";
import { Link } from "gatsby";


const TagItem = ({name}) => {
  return <TagItemContainer>{name}</TagItemContainer>;
};

export const Tag = ({tags}) => {
  return (
      <Container>
        {tags.map(tagname => {
          return (
            <Link
              key={tagname}
              style={{textDecoration: "none"}}
              to={`/tags/${kebabCase(tagname)}/`}>
              <TagItem name={tagname} />
            </Link>
          );
        })}
      </Container>
  );
};

const TagItemContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 12px;
  margin-right: 4px;
  height: 22px;
  line-height: 20px;
  border-radius: 12px;
  position: relative;
  top: -2px;
  color: rgba(27, 28, 37, 0.7);
  border-color: rgba(27, 28, 37, 0.3);
  :hover {
    border-color: rgba(27, 28, 37, 0.7);
    color: rgba(27, 28, 37, 1.0);
  }
  transition: all 0.15s ease-out;
`;

const Container = styled.div`
  position: relative;
  display: inline-flex;
`;
