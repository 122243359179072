import styled from "styled-components";

export const ArticleTitle = styled.h3`
  font-size: 1.5rem;
  font-family: 'Noto Sans KR', san-serif;
  font-weight: 900;
  margin-bottom: 8px;
  color: ${props => props.isDraft ? "rgba(27, 28, 37, 0.4)" : "rgba(27, 28, 37, 0.8)"};
  :hover {
    text-decoration: underline;
  }
`;
