import React from "react";
import { Link, graphql } from "gatsby";
import { ArticleTitle, Tag } from "../components";

import Layout from "../components/layout";
import SEO from "../components/seo";


const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO title="All posts" />
      {posts
        .filter(({ node }) => process.env.NODE_ENV === "production" ? node.frontmatter.stage === "published" : true)
        .map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          const isDraft = process.env.NODE_ENV === "development" && node.frontmatter.stage === "draft";
          const tags = node.frontmatter.tags;

          return (
            <article key={node.fields.slug}>
              <header>
                <ArticleTitle isDraft={isDraft}>
                  <Link style={{
                    boxShadow: `none`, color: "inherit",
                    textDecoration: "none",
                  }}
                    to={node.fields.slug}>
                    {title}
                  </Link>
                </ArticleTitle>
              </header>
              <section>
                <div style={{ marginTop: "0px", marginBottom: "36px" }}>
                  <span style={{ color: "rgba(27, 28, 37, 0.6)" }}>
                    {node.frontmatter.date} &nbsp;
                  </span>
                  <Tag tags={tags} />
                  <span style={{color : "rgba(27, 28, 37, 0.8)"}}>
                    {node.frontmatter.description || node.excerpt}
                  </span>
                </div>
              </section>
            </article>
          );
        })}
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC } ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            stage
            category
            tags
          }
        }
      }
    }
  }
`;
